import React from "react";
import Layout from "../components/Layout";
import SEO from "../components/seo";
import ContentContainer from "../components/ContentContainer";

const Page404 = ({ pageContext }) => (
	<Layout pageContext={pageContext}  >
		<SEO
			title={pageContext.PAGE_404_META_TITLE}
			description={pageContext.PAGE_404_META_DESCRIPTION}
			lang={pageContext.lang}
		/>
		<ContentContainer>
			<h1>{pageContext.PAGE_404_HEADLINE}</h1>
		</ContentContainer>
	</Layout>
);

export default Page404;
